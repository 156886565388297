import {ToNumber, ToNumberNullable, ToString, ToZipCode} from 'lib/utils';
import {DEFAULT_HOME_APPRECIATION} from 'constants/borrower';
import {calculateMaxAdvancedOptionsLength, getAge} from 'lib/borrower';
import {FUND_ACCESS} from 'constants/illustrator';
import {MIN_UPFRONT_DRAW_PERCENTAGE} from 'components/products/select/SelectHook';
import {defaultsDeep} from 'lodash';
import {DEFAULT_EXISTING_MORTGAGE} from 'reducers/illustrator.reducer';

export function getComparisonResponse(ApiRoute, data = {}, options) {
  const {advancedOptionsMap = (data) => data} = options;
  const TotalUpfrontDraw = ToNumber(data.TotalUpfrontDraw);
  const MaxRevolvingLoc = ToNumber(data.MaxRevolvingLoc);

  return {
    ApiRoute,
    CalculationId: data.CalculationId,
    BorrowerProfileId: data.BorrowerProfileId,
    ProductId: data.ProductId,

    Errors: data.Errors ?? [],
    CalculationWarnings: data.CalculationWarnings ?? [],

    FundAccess: ToString(
      Object.values(FUND_ACCESS).find(
        (FundAccess) => FundAccess.toLowerCase() === ToString(data.FundAccess).toLowerCase(),
      ),
      FUND_ACCESS.LINE_OF_CREDIT,
    ),
    Tenure: !!data.Tenure,

    SysCreateDate: data.SysCreateDate,

    recommended: false,
    PrincipalLimit: ToNumber(data.PrincipalLimit),
    AdjustedPL: ToNumber(data.AdjustedPL),
    ServiceFee: ToNumber(data.ServiceFee),
    LineOfCredit: ToNumber(data.LineOfCredit),
    RemainingPrincipalLimit: ToNumber(data.RemainingPrincipalLimit),
    RemainingEquity: ToNumber(data.RemainingEquity),
    FirstLien: ToNumberNullable(data.BorrowerProfile?.FirstLien),
    OtherLiens: ToNumberNullable(data.BorrowerProfile?.OtherLiens),
    SimplifiedCashToBorrower: ToNumber(data.SimplifiedCashToBorrower),
    TotalUpfrontDraw,
    MaxRevolvingLoc,
    ClosingCost: ToNumber(data.ClosingCost),
    CashAtClose: ToNumberNullable(data.CashAtClose),
    UnutilizedCash: ToNumberNullable(data.UnutilizedCash),
    InitialCashDraw: ToNumberNullable(data.InitialCashDraw),
    UnavailableHUDLimitProceeds: ToNumberNullable(data.UnavailableHUDLimitProceeds),
    HUDTotalDraw: ToNumberNullable(data.HUDTotalDraw),
    InitialLoanBalance: ToNumber(data.InitialLoanBalance),
    ExpectedRate: ToNumber(data.ExpectedRate),
    InitialRate: ToNumber(data.InitialRate),
    FixedRate: ToNumber(data.FixedRate),
    MIP: ToNumber(data.MIP),
    TotalInitialRate: ToNumber(data.TotalInitialRate),
    GrowthRate: ToNumber(data.GrowthRate * 100),

    MinimumInitialDraw: ToNumber(data.MinimumInitialDraw),
    OverrideClosingCosts: !!data.OverrideClosingCosts,
    OverrideClosingCostsKey: Math.random(),

    LenderCredit: !!data.LenderCredit,
    Lesa: !!data.Lesa,

    Fee: ToNumber(data.Fee),
    Rate: ToNumberNullable(data.Rate),
    Margin: ToNumber(data.Margin),

    upfrontDrawPercentage: ToNumber(
      ToNumber((TotalUpfrontDraw / MaxRevolvingLoc) * 100).toFixed(2),
      MIN_UPFRONT_DRAW_PERCENTAGE,
    ),
    TotalDraw: ToNumber(data.TotalDraw),
    TotalProceeds: ToNumber(data.TotalProceeds, ToNumber(data?.BorrowerProfile?.Liens) + ToNumber(data.CashAtClose)),
    BorrowerProfile: {
      Age: data.BorrowerProfile?.Age ?? '',
      BorrowerProfileId: data.BorrowerProfile?.BorrowerProfileId ?? '',
      DateOfBirth: data.BorrowerProfile?.DateOfBirth ?? '',
      Email: data.BorrowerProfile?.Email ?? '',
      ExistingMortgage: defaultsDeep({...data.BorrowerProfile.ExistingMortgage}, {...DEFAULT_EXISTING_MORTGAGE}),

      ExistingMortgageId: data.BorrowerProfile?.ExistingMortgageId ?? '',
      FirstName: data.BorrowerProfile?.FirstName ?? '',
      IsSaved: data.BorrowerProfile?.IsSaved ?? false,
      LastName: data.BorrowerProfile?.LastName ?? '',
      Liens: data.BorrowerProfile?.Liens ?? '',
      OwnerUserId: data.BorrowerProfile?.OwnerUserId ?? '',
      State: data.BorrowerProfile?.State ?? '',
      NumberOfUnits: data.BorrowerProfile?.NumberOfUnits ?? '',
      ValueOfProperty: data.BorrowerProfile?.ValueOfProperty ?? '',
      Address: data.BorrowerProfile?.Address ?? '',
      Zip: ToZipCode(data.BorrowerProfile?.Zip) ?? '',
      BorrowerPreferenceId: data.BorrowerProfile?.BorrowerPreferenceId,

      FirstLien: ToNumberNullable(data.BorrowerProfile?.FirstLien),
      OtherLiens: ToNumberNullable(data.BorrowerProfile?.OtherLiens),
    },
    IsSaved: data.IsSaved ?? false,

    showQuickFill: true,
    advancedOptions: data.HomeValues?.map((homeValue, index) => ({
      minMonthlyPayment: 0,
      year: ToNumber(homeValue.Year),
      age: ToNumber(data.Age) + ToNumber(homeValue.Year),
      homeValueYearEnd: ToNumber(homeValue.EndValue),
      homeValueYearStart: ToNumber(homeValue.StartValue),
      locYearStart: ToNumber(data.LocBalances?.[index]?.StartValue),
      locYearEnd: ToNumber(data.LocBalances?.[index]?.EndValue),
      tenurePayment: ToNumber(data.TenureResult?.TenureMonthlyPayments?.[index]?.PaymentValue, null),
      homeAppreciation: ToNumber(data.HomeAppreciationRates[index].AppreciationRate, DEFAULT_HOME_APPRECIATION),
      annualPayment: ToNumber(data.AnnualPayments[index].PaymentValue),
      monthlyPayment: ToNumber(data.MonthlyPayments[index].PaymentValue),
      rateAdjustments: ToNumber(data.RateAdjustments[index].AdjustmentValue),
      loanBalanceValueYearEnd: ToNumber(data.LoanBalances[index].EndValue),
      loanBalanceValueYearStart: ToNumber(data.LoanBalances[index].StartValue),
      netEquityValue: ToNumber(data.NetEquities[index].Equity),
      existingMortgageEndBalance: ToNumber(data.ExistingMortgageEndBalances[index]),
    })).map(advancedOptionsMap),
  };
}

export function getComparisonData(illustration = {}, config = {}) {
  const {withBorrowerProfile = true} = config;
  const {
    FundAccess,
    Tenure,
    CashAtClose,
    OverrideClosingCosts,
    ClosingCost,
    LenderCredit,
    BorrowerProfile: borrowerProfile = {},
    BorrowerProfileId = null,
    IsSaved = false,
  } = illustration;

  let {advancedOptions} = illustration;
  let BorrowerProfile = undefined;

  if (withBorrowerProfile || !BorrowerProfileId) {
    BorrowerProfile = {
      Age: getAge(borrowerProfile.DateOfBirth),
      FirstName: borrowerProfile.FirstName,
      LastName: borrowerProfile.LastName,
      BorrowerProfileId: borrowerProfile.BorrowerProfileId,
      DateOfBirth: borrowerProfile.DateOfBirth,
      Email: borrowerProfile.Email,
      ExistingMortgage: defaultsDeep({...borrowerProfile.ExistingMortgage}, {...DEFAULT_EXISTING_MORTGAGE}),
      ExistingMortgageId: borrowerProfile.ExistingMortgageId,
      Liens: ToNumber(borrowerProfile.Liens),
      OwnerUserId: borrowerProfile.OwnerUserId,
      Address: borrowerProfile.Address,
      NumberOfUnits: borrowerProfile.NumberOfUnits,
      State: borrowerProfile.State,
      ValueOfProperty: ToNumber(borrowerProfile.ValueOfProperty),
      Zip: ToZipCode(borrowerProfile.Zip),
      BorrowerPreferenceId: borrowerProfile.BorrowerPreferenceId,
      FirstLien: ToNumberNullable(borrowerProfile.FirstLien),
      OtherLiens: ToNumberNullable(borrowerProfile.OtherLiens),
    };
  }

  if (!advancedOptions?.length) {
    advancedOptions = Array.from(Array(calculateMaxAdvancedOptionsLength()));
  }

  return {
    IsSaved,
    OverrideClosingCosts: !!OverrideClosingCosts,

    BorrowerProfileId: BorrowerProfileId,
    BorrowerProfile: BorrowerProfile,

    LenderCredit: !!LenderCredit,

    FundAccess: ToString(FundAccess),
    Tenure: !!Tenure,
    ClosingCost: ToNumber(ClosingCost),
    CashAtClose: ToNumberNullable(CashAtClose),
    HomeAppreciation: advancedOptions?.map((option) => ToNumber(option?.homeAppreciation, DEFAULT_HOME_APPRECIATION)),
    AnnualPayments: advancedOptions?.map((option) => ToNumber(option?.annualPayment)),
    MonthlyPayments: advancedOptions?.map((option) => ToNumber(option?.monthlyPayment)),
    RateAdjustments: advancedOptions?.map((option) => ToNumber(option?.rateAdjustments)),
  };
}
